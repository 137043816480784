import axios from '@/helpers/axios';
import router from '@/router';
import dataStore from '@/helpers/data';
import helperError from '@/helpers/error';
import helperGather from '@/helpers/gather';
const state = {
  list: [],
  owner: { userId: '', name: '' },
  info: {
    name: '',
    token: '',
    size: 0,
    size0: 0,
    size1: 0,
  },
  config: [],
  checked: false,
  settings: false,
  players: [],
  server: {
    ready: false,
    status: 0,
    locked: false,
    loading: false,
    started: false,
    name: '',
    tv: '',
    ip: '',
    password: '',
  },
  timer: {
    time: 1800,//600,
    list: null,
    info: null,
    config: null,
    settings: null,
    players: null,
    server: null,
    update: null,
  },
  paused: false,
};
const getters = {
  gather: state => state
};
const actions = {
  reset({ commit }){
    commit('SET_TOKEN', '');
    commit('SET_LIST', []);
    commit('SET_INFO', undefined);
    commit('SET_OWNER', { userId: '', name: '' });
    commit('SET_PLAYERS', []);
    commit('SET_CONFIG', []);
    commit('SET_SERVER', { tv: '', name: '', ip: '', password: '', });
    commit('SET_SERVER_LOADING', false);
    commit('SET_SERVER_STARTED', false);
    commit('SET_SERVER_LOCKED', true);
    clearInterval(state.timer.list);
    clearInterval(state.timer.info);
    clearInterval(state.timer.config);
    clearInterval(state.timer.players);
    clearInterval(state.timer.server);
    clearInterval(state.timer.settings);
    this.dispatch('gather/updating');
    this.dispatch('page/name', '');
    this.dispatch('map/reset');
  },
  updating({ commit }){
    commit('SET_PAUSED', true);
    clearTimeout(state.timer.update);
    state.timer.update = setTimeout(() => {
      commit('SET_PAUSED', false);
    }, state.timer.time);
  },
  list({ commit }) {
    this.dispatch('app/loading', true);
    state.timer.list = setInterval(() => {
      if(!state.paused){
        axios.get('/gather').then(
          response => {
            this.dispatch('app/loading', false);
            commit('SET_LIST', response.data.response);
          },
          error => {
            helperError(this, error, 'An error occured.');
          }
        );
      }
    }, state.timer.time);
  },/**/
  async info({ commit }) {
    this.dispatch('app/loading', true);
    state.timer.info = setInterval(() => {
      if(!state.paused){
        axios.get('/gather/' + state.info.token).then(
          response => {
            this.dispatch('app/loading', false);
            if(response.data.response !== undefined){
              this.dispatch('map/type', { type: response.data.response.mapSelection, send: false });
              commit('SET_INFO', response.data.response);
            } else {
              this.dispatch('notification/text', { type: 'error', msg: 'Gather cancelled.', persist: true });
            }
          },
          error => {
            helperError(this, error, 'An error occured.');
          }
        );
      }
    }, state.timer.time);
  },/**/
  players({ commit }) {
    this.dispatch('app/loading', true);
    state.timer.players = setInterval(() => {
      if(!state.paused){
        axios.get('/gather/' + state.info.token + '/players').then(
          response => {
            this.dispatch('app/loading', false);
            if(response.data.response !== undefined){
              let list = response.data.response;
              if (list.length < 1) {
                router.push({ name: 'ViewGatherList' });
              } else {
                commit('SET_OWNER', { userId: list[0].userId, name: list[0].userName });
                commit('SET_PLAYERS', list);
              }
            } else {
              router.push({ name: 'ViewGatherList' });
            }
          },
          error => {
            helperError(this, error, 'An error occured.');
          }
        );
      }
    }, state.timer.time);
  },/**/
  async scramble({ commit }) {
    this.dispatch('app/loading', true);
    this.dispatch('gather/updating');
    const data = dataStore.getter(this.getters);
    let userToken = null;
    if(data.user.token !== ''){ userToken = data.user.token }
    else if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ userToken = localStorage.getItem('user') }
    if (userToken === null || userToken === ''){ router.push({ name: 'ViewHome' }); }
    else {
      return await axios.patch('/gather/' + state.info.token + '/teambalance', {}, { headers: { userToken } }).then(
        () => {
          this.dispatch('app/loading', false);
          commit('SET_PAUSED', false);
        },
        error => {
          helperError(this, error, 'An error occured.');
        }
      );
    }
  },/**/
  async team({ commit }, parameter){
    this.dispatch('app/loading', true);
    this.dispatch('gather/updating');
    const data = dataStore.getter(this.getters);
    let userToken = null;
    if(data.user.token !== ''){ userToken = data.user.token }
    else if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ userToken = localStorage.getItem('user') }
    if (userToken === null || userToken === ''){ router.push({ name: 'ViewHome' }); }
    else {
      return await axios.patch('/gather/' + state.info.token + '/team', { userId: parameter.player, team: parameter.team }, { headers: { userToken } }).then(
        () => {
          this.dispatch('app/loading', false);
          commit('SET_PAUSED', false);
        },
        error => {
          helperError(this, error, 'An error occured.');
        }
      );
    }
  },/**/
  async ready({ commit }, parameter){
    this.dispatch('app/loading', true);
    this.dispatch('gather/updating');
    const data = dataStore.getter(this.getters);
    let userToken = null;
    if(data.user.token !== ''){ userToken = data.user.token }
    else if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ userToken = localStorage.getItem('user') }
    if (userToken === null || userToken === ''){ router.push({ name: 'ViewHome' }); }
    else {
      return await axios.patch('/gather/' + state.info.token + '/me', { ready: parameter.ready ? 1 : 0 }, { headers: { userToken } }).then(
        () => {
          this.dispatch('app/loading', false);
          commit('SET_PAUSED', false);
        },
        error => {
          helperError(this, error, 'An error occured.');
        }
      );
    }
  },/**/
  async config({ commit }) {
    this.dispatch('app/loading', true);
    state.timer.config = setInterval(() => {
      if(!state.paused){
        axios.get('/gather/' + state.info.token + '/config').then(
          response => {
            this.dispatch('app/loading', false);
            if(response.data.response !== undefined){
              if(response.data.response.length > 0){
                commit('SET_CONFIG', response.data.response.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0)));
              } else {
                const data = dataStore.getter(this.getters);
                let userToken = null;
                if(data.user.token !== ''){ userToken = data.user.token }
                else if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ userToken = localStorage.getItem('user') }
                if (state.owner.userId === userToken){
                  this.dispatch('gather/settings');
                }
              }
            } else {
              router.push({ name: 'ViewGatherList' });
            }
          },
          error => {
            helperError(this, error, 'An error occured.');
          }
        );
      }
    }, state.timer.time);
  },/**/
  async update({ commit }, parameter) {
    this.dispatch('app/loading', true);
    this.dispatch('gather/updating');
    const data = dataStore.getter(this.getters);
    let userToken = null;
    if(data.user.token !== ''){ userToken = data.user.token }
    else if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ userToken = localStorage.getItem('user') }
    if (userToken === null || userToken === ''){ router.push({ name: 'ViewHome' }); }
    else {
      return await axios.patch('/gather/' + state.info.token + '/config', { key: parameter.item.key, value: { name: parameter.item.value.name, type: parameter.item.value.type, value: parameter.item.value.value } }, { headers: { userToken } }).then(
        () => {
          this.dispatch('app/loading', false);
          commit('SET_PAUSED', false);
        },
        error => {
          helperError(this, error, 'An error occured.');
        }
      );
    }
  },/**/
  settings({ commit }) {
    this.dispatch('app/loading', true);
    state.timer.settings = setInterval(() => {
      if(!state.paused && !state.checked && !state.settings){
        const data = dataStore.getter(this.getters);
        if(state.owner.userId === data.user.userId){
          let userToken = null;
          if(data.user.token !== ''){ userToken = data.user.token }
          else if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ userToken = localStorage.getItem('user') }
          if (userToken === null || userToken === ''){ router.push({ name: 'ViewHome' }); }
          else {
            let promises = []
            helperGather.settings.forEach(item => {
              this.dispatch('app/loading', true);
              promises.push(new Promise((resolve, reject) => {
                axios.patch('/gather/' + state.info.token + '/config', { key: item.key, value: item.value }, { headers: { userToken } }).then(
                  () => {
                    this.dispatch('app/loading', false);
                    this.dispatch('gather/updating');
                    commit('SET_SETTINGS', true);
                    resolve();
                  }, error => {
                    helperError(this, error, 'An error occured.');
                    reject();
                  }
                )
              }));
            });
            Promise.all(promises).then(() => {
              clearInterval(state.timer.settings);
              commit('SET_CHECKED', true);
              setTimeout(() => {
                commit('SET_PAUSED', false);
                this.dispatch('gather/config');
              }, state.timer.time * 5);
              return true;
            });
          }
        } else {
          commit('SET_SETTINGS', true);
          return true;
        }
      }
    }, state.timer.time);
  },/**/
  async action({ commit }, parameter) {
    this.dispatch('app/loading', true);
    this.dispatch('gather/updating');
    commit('SET_SERVER_LOADING', true);
    commit('SET_SERVER_LOCKED', true);
    const data = dataStore.getter(this.getters);
    let userToken = null;
    if(data.user.token !== ''){ userToken = data.user.token }
    else if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ userToken = localStorage.getItem('user') }
    if (userToken === null || userToken === ''){ router.push({ name: 'ViewHome' }); }
    else {
      return await axios.patch('/gather/' + state.info.token + '/action', { action: parameter.action }, { headers: { userToken } }).then(
        () => {
          commit('SET_SERVER_LOADING', false);
          commit('SET_SERVER_STARTED', parameter.action === 'stop' ? false : true);
          commit('SET_SERVER_LOCKED', parameter.action === 'stop' ? false : true);
        },
        error => {
          helperError(this, error, 'An error occured.');
        }
      );
    }
  },/**/
  server({ commit }) {
    const data = dataStore.getter(this.getters);
    let userToken = null;
    if(data.user.token !== ''){ userToken = data.user.token }
    else if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ userToken = localStorage.getItem('user') }
    if (userToken === null || userToken === ''){ router.push({ name: 'ViewHome' }); }
    else {
      state.timer.server = setInterval(() => {
        if(!state.paused){
          axios.get('/gather/' + state.info.token + '/host',{ parameter: { userId: data.user.userId }}, { headers: { userToken } }).then(
            response => {
              if(response.data.response !== undefined){
                let mode = response.data.response.status === 1 ? true : false;
                let locked = response.data.response.status !== 0 ? true : false;
                let gameover = response.data.response.status !== 0 && response.data.response.status !== 1 ? true : false;
                if(gameover){
                  commit('SET_PAUSED', true);
                }
                commit('SET_SERVER_STATUS', response.data.response.status);
                commit('SET_SERVER_STARTED', mode);
                commit('SET_SERVER_LOCKED', locked);
                commit('SET_SERVER', { tv: response.data.response.goTv, name: response.data.response.serverName, ip: response.data.response.serverIp, password: response.data.response.serverPassword, });
              } else {
                router.push({ name: 'ViewGatherList' });
              }
            },
            error => {
              commit('SET_SERVER_LOADING', false);
              commit('SET_SERVER_STARTED', false);
              commit('SET_SERVER_LOCKED', false);
              helperError(this, error, 'An error occured.');
            }
          );
        }
      }, state.timer.time);
    }
  },/**/
  token({ commit }, token) {
    this.dispatch('gather/updating');
    commit('SET_TOKEN', token);
  },/**/
};
const mutations = {
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_INFO(state, payload) {
    if(payload !== undefined){
      state.info.name = payload.channelName;
      state.info.size = payload.maxPlayers;
      state.info.size0 = payload.maxPlayers / 2;
      state.info.size1 = payload.maxPlayers / 2;
    } else {
      state.info.name = '';
      state.info.size = 10;
      state.info.size0 = 5;
      state.info.size1 = 5;
    }
  },
  SET_PLAYERS(state, payload) {
    if(payload !== null){
      state.players = payload;
    }
  },
  SET_OWNER(state, payload) {
    state.owner = payload;
  },
  SET_CONFIG(state, payload) {
    state.config = payload;
  },
  SET_SERVER(state, payload) {
    state.server.name = payload.name;
    state.server.tv = payload.tv;
    state.server.ip = payload.ip;
    state.server.password = payload.password;
  },
  SET_SERVER_STATUS(state, payload) {
    state.server.status = payload;
  },
  SET_SERVER_LOCKED(state, payload) {
    state.server.locked = payload;
  },
  SET_SERVER_LOADING(state, payload) {
    state.server.loading = payload;
  },
  SET_SERVER_STARTED(state, payload) {
    state.server.started = payload;
  },
  SET_TOKEN(state, payload){
    state.info.token = payload;
  },
  SET_CHECKED(state, payload){
    state.checked = payload;
  },
  SET_SETTINGS(state, payload){
    state.settings = payload;
    clearTimeout(state.timer.list);
    clearTimeout(state.timer.info);
    clearTimeout(state.timer.config);
    clearTimeout(state.timer.settings);
    clearTimeout(state.timer.players);
    clearTimeout(state.timer.server);
    clearTimeout(state.timer.update);
  },
  SET_PAUSED(state, parameter){
    state.paused = parameter;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};