import axios from 'axios';
import config from '@/helpers/config';
var url = config.api.url.axios + config.api.version.axios;
const instance = axios.create({
  baseURL: url,
  withCredentials: false,
  headers: {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
    "Content-Type": "application/json",
    "apiKey": config.api.key.axios,
  },
});
export default instance;