<template>
  <header>
    <Navigation />
    <Breadcrumb />
  </header>
</template>

<script>
import Navigation from '@/components/core/header/Navigation.vue';
import Breadcrumb from '@/components/core/header/Breadcrumb.vue';
export default {
  name: 'ComponentCoreHeader',
  components: {
    Navigation,
    Breadcrumb,
  },
  computed: {},
  props: {},
  data() {
    return {};
  },
  mounted() {},
  updated() {},
  methods: {},
};
</script>
