export default {
  api: {
    url: {
      axios: 'https://api.cslobby.gg/',
      sockets: 'https://api.cslobby.gg/'
    },
    version: {
      axios: 'v1',
    },
    key: {
      axios: 'YZmTozhjN547jccoo3FBMx8F3dr9ywEu3sB7fgySoLA9BFmxVKVmMfk7GA5BXs2W',
      bugsnag: '670a4193e00b289f0cb9e567ffbf3d4d'
    }
  },
  communication: [
    {
      name: 'Discord',
      alias: 'discord',
      url: 'https://discord.gg/ZcHBGctEWx'
    }
  ]
};