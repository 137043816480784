const state = {
  persist: false,
  type: '',
  text: '',
  show: false,
  timer: null,
  time: 5000,
  animation: 300
};
const getters = {
  notification: state => state
};
const actions = {
  text({ commit }, input) {
    clearTimeout(state.timer);
    if(input.msg !== '') {
      commit('SET_TEXT', input.msg);
      commit('SET_TYPE', input.type);
      commit('SET_PERSIST', input.persist);
      state.timer = setTimeout(() => {
        commit('SET_SHOW', true);
      }, state.animation);
    } else {
      commit('SET_SHOW', false);
      state.timer = setTimeout(() => {
        commit('SET_TEXT', input.msg);
        commit('SET_TYPE', input.type);
        commit('SET_PERSIST', input.persist);
      }, state.animation);
    }
  },
};
const mutations = {
  SET_PERSIST(state, payload){
    state.persist = payload;
  },
  SET_TYPE(state, payload) {
    state.type = payload;
  },
  SET_TEXT(state, payload) {
    state.text = payload;
  },
  SET_SHOW(state, payload) {
    state.show = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};