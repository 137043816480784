import camelCase from 'lodash/camelCase';
const getter = getters => {
  let data = {};
  const requireModule = require.context('@/store/modules/', false, /\.js$/);
  requireModule.keys().forEach(filename => {
    if (filename === './index.js') return;
    const moduleName = camelCase(filename.replace(/(\.\/|\.js)/g, ''));
    data[moduleName] = getters[moduleName + '/' + moduleName];
  });
  return data;
};

export default {
  getter
};
