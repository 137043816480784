import axios from '@/helpers/axios';
import router from '@/router';
import dataStore from '@/helpers/data';
import helperError from '@/helpers/error';
const state = {
  gather: null,
  token: '',
  email: '',
  userId: '',
  steam: false,
  discord: false,
};
const getters = {
  user: state => state
};
const actions = {
  async gather({ commit }){
    let token = null;
    if(router.currentRoute._value.name !== 'ViewGatherInfo') {
      if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ token = localStorage.getItem('user') }
      else if(state.token !== ''){ token = state.token }
      return await axios.get('/gather/me', { headers: { userToken: token } }).then(
        response => {
          if (response.data.response.length > 0) {
            let gather = ''
            commit('SET_GATHER', gather);
            this.dispatch('notification/text', { type: 'warning', msg: 'Looks like you have a gather: <a href="/play/' + gather + '">join here</a>', persist: true });
            console.log("GATHER CHECK - CURRENTLY NO RESPONSE", response.data.response);
          }
        },
        error => {
          helperError(this, error, 'An error occured.');
        }
      );
    }
  },
  setToken({ commit }, payload){
    commit('SET_TOKEN', payload);
  },
  setEmail({ commit }, payload){
    commit('SET_EMAIL', payload);
  },
  setDiscord({ commit }, payload){
    commit('SET_DISCORD', payload);
  },
  setSteam({ commit }, payload){
    commit('SET_STEAM', payload);
  },
  async create({ commit }, parameters) {
    this.dispatch('app/loading', true);
    return await axios.post('/user', { emailAddress: parameters.email, password: parameters.password }).then(
      () => {
        commit('SET_TOKEN', '');
        commit('SET_EMAIL', parameters.email);
        this.dispatch('notification/text', { type: 'success', msg: 'User created, please login.', persist: false });
        this.dispatch('app/loading', false);
        router.push({ name: 'ViewUserLogin' });
      },
      error => {
        helperError(this, error, 'An error occured.');
      }
    );
  },
  async login({ commit }, parameters) {
    this.dispatch('app/loading', true);
    return await axios.post('/user/login', { emailAddress: parameters.email, password: parameters.password }).then(
      response => {
        const data = dataStore.getter(this.getters);
        commit('SET_TOKEN', response.data.response.userToken);
        commit('SET_EMAIL', parameters.email);
        commit('SET_USERID', response.data.response.userId);
        commit('SET_DISCORD', response.data.response.hasDiscord);
        commit('SET_STEAM', response.data.response.hasSteam);
        let locked = true;
        if(!response.data.response.hasSteam === false && !response.data.response.hasDiscord === false){ locked = false; }
        this.dispatch('notification/text', { type: 'success', msg: 'You have been logged on.', persist: false });
        if (data.notification.show || data.notification.text !== '') {
          window.setTimeout(() => {
            this.dispatch('user/gather');
          }, data.notification.time / 2)
        } else {
          this.dispatch('user/gather');
        }
        this.dispatch('app/locked', locked);
        this.dispatch('app/loading', false);
        if(!locked && data.page.from === 'ViewGatherInfo'){
          router.push({ name: data.page.from });
        } else if(locked){
          router.push({ name: 'ViewUserInfo' });
        } else {
          router.push({ name: 'ViewGatherList' });
        }
      },
      error => {
        helperError(this, error, 'An error occured.');
      }
    );
  },
  async logout({ commit }) {
    this.dispatch('app/loading', true);
    return await axios.get('/user/logout', { headers: { userToken: state.token } }).then(
      () => {
        commit('SET_TOKEN', '');
        commit('SET_EMAIL', '');
        commit('SET_USERID', '');
        commit('SET_DISCORD', false);
        commit('SET_STEAM', false);
        this.dispatch('notification/text', { type: 'success', msg: 'You have been logged out.', persist: false });
        this.dispatch('app/loading', false);
        router.push({ name: 'ViewHome' });
      },
      error => {
        helperError(this, error, 'An error occured.');
      }
    );
  },
  async forgot({ commit }, parameters) {
    this.dispatch('app/loading', true);
    return await axios.post('/user/reset', { emailAddress: parameters.email }).then(
      () => {
        commit('SET_TOKEN', '');
        commit('SET_EMAIL', parameters.email);
        this.dispatch('notification/text', { type: 'success', msg: 'Reset mail sent successfully.', persist: false });
        this.dispatch('app/loading', false);
      },
      error => {
        helperError(this, error, 'An error occured.');
      }
    );
  },
  async reset({ commit }, parameters) {
    this.dispatch('app/loading', true);
    return await axios.patch('/user/reset', { password: parameters.password }, { headers: { resetHash: parameters.token } }).then(
      () => {
        commit('SET_TOKEN', '');
        commit('SET_EMAIL', '');
        this.dispatch('notification/text', { type: 'success', msg: 'User reset success.', persist: false });
        this.dispatch('app/loading', false);
        router.push({ name: 'ViewHome' });
      },
      error => {
        helperError(this, error, 'An error occured.');
      }
    );
  },
  async info({ commit }) {
    this.dispatch('app/loading', true);
    return await axios.get('/user', { headers: { userToken: state.token } }).then(
      response => {
        if(response.data && response.data.success){
          commit('SET_EMAIL', response.data.response.emailAddress);
          commit('SET_DISCORD', response.data.response.hasDiscord);
          commit('SET_STEAM', response.data.response.hasSteam);
        } else {
          commit('SET_TOKEN', '');
          commit('SET_EMAIL', '');
          commit('SET_DISCORD', false);
          commit('SET_STEAM', false);
        }
        commit('SET_EMAIL', '');
        this.dispatch('app/loading', false);
      },
      error => {
        helperError(this, error, 'An error occured.');
      }
    );
  },
  async check({ commit }) {
    this.dispatch('app/loading', true);
    let token = null;
    if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ token = localStorage.getItem('user') }
    else if(state.token !== ''){ token = state.token }
    return await axios.get('/user/token', { headers: { userToken: token } }).then(
      response => {
        if(response.data && response.data.success){
          const data = dataStore.getter(this.getters);
          commit('SET_TOKEN', token);
          commit('SET_USERID', response.data.response.userId);
          commit('SET_DISCORD', response.data.response.hasDiscord);
          commit('SET_STEAM', response.data.response.hasSteam);
          if(!response.data.response.hasSteam === false && !response.data.response.hasDiscord === false) {
            this.dispatch('app/locked', false);
            if (data.notification.show || data.notification.text !== '') {
              window.setTimeout(() => {
                this.dispatch('user/gather');
              }, data.notification.time / 2);
            } else {
              this.dispatch('user/gather');
            }
          }
          else {
            this.dispatch('app/locked', true);
          }
        } else {
          commit('SET_TOKEN', '');
          commit('SET_USERID', '');
          commit('SET_DISCORD', false);
          commit('SET_STEAM', false);
          this.dispatch('app/locked', true);
        }
        commit('SET_EMAIL', '');
        this.dispatch('app/loading', false);
      },
      error => {
        helperError(this, error, 'An error occured.');
      }
    );
  },
};
const mutations = {
  SET_GATHER(state, payload){
    state.gather = payload;
  },
  SET_EMAIL(state, payload) {
    state.email = payload;
  },
  SET_TOKEN(state, payload) {
    localStorage.setItem('user', payload);
    state.token = payload;
  },
  SET_USERID(state, payload) {
    state.userId = payload;
  },
  SET_DISCORD(state, payload) {
    state.discord = payload;
  },
  SET_STEAM(state, payload) {
    state.steam = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
