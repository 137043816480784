<template>
  <footer>
    <div class="container">
      <div class="content">
        <span v-if="data.language.list.length > 1"><ChangeLanguage /></span>
        <span>© {{ year }} CSLobby.gg</span>
        <span>{{ content[data.language.current].text }}</span>
        <span><router-link to="/staff">Staff</router-link></span>
        <span><router-link to="/cookies">Cookies</router-link></span>
        <span><router-link to="/gdpr">GDPR</router-link></span>
      </div>
    </div>
  </footer>
</template>

<script>
import ChangeLanguage from '@/components/core/footer/ChangeLanguage.vue';
import dataStore from '@/helpers/data';
export default {
  name: 'ComponentCoreFooter',
  components: {
    ChangeLanguage,
  },
  computed: {
    data() {
      return dataStore.getter(this.$store.getters);
    },
  },
  props: {},
  data() {
    return {
      year: '',
      content: {
        en: {
          text: 'All rights reserved.',
        },
        da: {
          text: 'Alle rettigheder forbeholdes.',
        },
      },
    };
  },
  mounted() {
    let start = 2021;
    this.year = start;
    if (new Date().getFullYear() > start) {
      this.year += '-' + new Date().getFullYear();
    }
  },
  updated() {},
  methods: {},
};
</script>
