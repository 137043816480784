<template>
  <nav class="navigation">
    <router-link class="logo" to="/">CS<span>Lobby.gg</span></router-link>
    <ul class="menu">
      <li v-if="data.user.token !== ''">
        <router-link to="/play">{{ content[data.language.current].play }}</router-link>
      </li>
      <li :key="item.name" v-for="item in communication">
        <a target="_blank" v-if="data.user[item.alias] === false || data.user[item.alias] === undefined" :href="item.url">{{ item.name }}</a>
      </li>
    </ul>
    <ul class="account">
      <li v-if="data.user.token === ''">
        <router-link to="/user/create">{{ content[data.language.current].create }}</router-link>
      </li>
      <li v-if="data.user.token === ''">
        <router-link to="/user/login">{{ content[data.language.current].login }}</router-link>
      </li>
      <li v-if="data.user.token === ''">
        <router-link to="/user/discord">
          <div id="signinWithDiscord">
            Login with
            <img src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0b544a3e3c7c05753bcd_full_logo_white_RGB.png" />
          </div>
        </router-link>
      </li>
      <li v-if="data.user.token !== ''">
        <router-link to="/user">{{ content[data.language.current].user }}</router-link>
      </li>
      <li v-if="data.user.token !== ''">
        <a @click="logout">
          {{ content[data.language.current].logoff }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import dataStore from '@/helpers/data';
import config from '@/helpers/config';
export default {
  name: 'ComponentCoreHeaderNavigation',
  components: {},
  computed: {
    data() {
      return dataStore.getter(this.$store.getters);
    },
  },
  props: {},
  data() {
    return {
      communication: [],
      content: {
        en: {
          play: 'Play Now',
          login: 'Login',
          create: 'Create',
          user: 'User',
          logoff: 'Logoff',
        },
        da: {
          play: 'Spil nu',
          login: 'Log på',
          create: 'Opret',
          user: 'Bruger',
          logoff: 'Log af',
        },
      },
    };
  },
  mounted() {
    this.communication = config.communication;
  },
  updated() {
    this.communication = config.communication;
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout');
    },
  },
};
</script>