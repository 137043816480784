const state = {
  loading: false,
  locked: true,
};
const getters = {
  app: state => state
};
const actions = {
  loading({ commit }, mode) {
    commit('SET_LOADING', mode);
  },
  locked({ commit }, mode) {
    commit('SET_LOCKED', mode);
  },
};
const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_LOCKED(state, payload) {
    state.locked = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};