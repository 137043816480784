import $axios from '@/helpers/axios';
import helperError from '@/helpers/error';
const state = {
  list: []
};
const getters = {
  staff: state => state
};
const actions = {
  async list({ commit }) {
    this.dispatch('app/loading', true);
    return await $axios.get('/staff').then(
      response => {
        this.dispatch('app/loading', false);
        commit('SET_LIST', response.data.response);
      },
      error => {
        helperError(this, error, 'An error occured.');
      }
    );
  },
};
const mutations = {
  SET_LIST(state, payload) {
    state.list = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};