import axios from '@/helpers/axios';
import dataStore from '@/helpers/data';
import router from '@/router';
import helperError from '@/helpers/error';
const state = {
  maps: {
    grouped: [],
    select: [],
  },
  ready: true,
  type: 'random',
  selected: '',
  nominated: [],
  nominations: [],
  voting: [],
  votes: [],
  timer: {
    time: 1800,//600,
    poll: null,
    nomination: null,
    update: null,
  },
  paused: false,
};
const getters = {
  map: state => state
};
const actions = {
  reset({ commit }){
    commit('SET_READY', false);
    commit('SET_TYPE', 'random');
    commit('SET_NOMINATED', []);
    commit('SET_NOMINATIONS', []);
    commit('SET_VOTING', []);
    commit('SET_VOTES', []);
    commit('SET_SELECTED', '');
    clearInterval(state.timer.poll);
    clearInterval(state.timer.nomination);
  },
  updating({ commit }){
    commit('SET_PAUSED', false);
  },
  ready({ commit }, mode){
    commit('SET_READY', mode);
  },
  async maps({ commit }) {
    const data = dataStore.getter(this.getters);
    this.dispatch('app/loading', true);
    return await axios.get('/gather/' + data.gather.info.token + '/availableMaps').then(
      response => {
        this.dispatch('app/loading', false);
        let list = [];
        if(response.data.response !== undefined) {
          response.data.response.forEach((group) => {
            group.maps.forEach((map) => {
              const current = { group: { id: group.mapGroupId, name: group.name }, map: { id: map.mapId, name: map.name, workshopId: map.workshopId } };
              list.push(current)
            })
          })
          commit('SET_MAPS_GROUPED', response.data.response);
          commit('SET_MAPS_SELECT', list);
          commit('SET_READY', true);
        } else {
          helperError(this, 'maps list: ' + response.data.response, 'An error occured.');
        }
      },
      error => {
        helperError(this, error, 'An error occured.');
      }
    );
  },
  async type({ commit }, parameter){
    this.dispatch('map/updating');
    if(parameter.mode){
      const data = dataStore.getter(this.getters);
      this.dispatch('app/loading', true);
      let userToken = null;
      if(data.user.token !== ''){ userToken = data.user.token }
      else if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ userToken = localStorage.getItem('user') }
      if (userToken === null || userToken === ''){ router.push({ name: 'ViewHome' }); }
      else {
        return await axios.patch('/gather/' + data.gather.info.token, { key: 'mapSelection', value: parameter.type }, { headers: { userToken } }).then(
          () => {
            this.dispatch('app/loading', false);
            commit('SET_TYPE', parameter.type);
          },
          error => {
            helperError(this, error, 'An error occured.');
          }
        );
      }
    } else {
      commit('SET_TYPE', parameter.type);
    }
  },
  async nominating({ commit }, payload){
    const data = dataStore.getter(this.getters);
    this.dispatch('app/loading', true);
    this.dispatch('map/updating');
    let userToken = null;
    if(data.user.token !== ''){ userToken = data.user.token }
    else if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ userToken = localStorage.getItem('user') }
    if (userToken === null || userToken === ''){ router.push({ name: 'ViewHome' }); }
    else {
      let nominated = '';
      if(payload.name === undefined) {
        nominated = payload[0].name;
      } else {
        nominated = payload.name;
      }
      return await axios.patch('/gather/' + data.gather.info.token + '/suggestion', { map: nominated }, { headers: { userToken } }).then(
        () => {
          this.dispatch('app/loading', false);
          commit('SET_NOMINATED', payload);
        },
        error => {
          helperError(this, error, 'An error occured.');
        }
      );
    }
  },
  async nominations({ commit }){
    const data = dataStore.getter(this.getters);
    state.timer.nomination = setInterval(() => {
      if(!state.paused){
        axios.get('/gather/' + data.gather.info.token + '/suggestion').then(
          response => {
            commit('SET_NOMINATIONS', response.data.response);
          },
          error => {
            helperError(this, error, 'An error occured.');
          }
        );
      }
    }, state.timer.time);
  },
  async voting({ commit }, votes){
    const data = dataStore.getter(this.getters);
    this.dispatch('app/loading', true);
    this.dispatch('map/updating');
    let userToken = null;
    if(data.user.token !== ''){ userToken = data.user.token }
    else if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ userToken = localStorage.getItem('user') }
    if (userToken === null || userToken === ''){ router.push({ name: 'ViewHome' }); }
    else {
      return await axios.patch('/gather/' + data.gather.info.token + '/voting', { vote: votes }, { headers: { userToken } }).then(
        () => {
          this.dispatch('app/loading', false);
          commit('SET_VOTING', votes);
        },
        error => {
          helperError(this, error, 'An error occured.');
        }
      );
    }
  },
  async votes({ commit }){
    const data = dataStore.getter(this.getters);
    state.timer.poll = setInterval(() => {
      if(!state.paused){
        axios.get('/gather/' + data.gather.info.token + '/voting').then(
          response => {
            commit('SET_VOTES', response.data.response);
          },
          error => {
            helperError(this, error, 'An error occured.');
          }
        );
      }
    }, state.timer.time);
  },
  async selected({ commit }, parameter){
    this.dispatch('map/updating');
    let mapString = '';
    if(parameter.send) {
      let input = '';
      if(input.name !== undefined) {
        mapString += parameter.name;
      } else {
        parameter.maps.forEach((map, i) => {
          if(i > 0) {
            mapString += ','
          }
          mapString += map.name;
        })
      }

      const data = dataStore.getter(this.getters);
      let item = { key: 'map', value: { name: 'Map', type: 'map', value: mapString } };
      this.dispatch('gather/update', { token: data.gather.info.token, item: item });
    } else {
      mapString = parameter.maps;
    }
    commit('SET_SELECTED', mapString);
  },
};
const mutations = {
  SET_MAPS_GROUPED(state, payload) {
    state.maps.grouped = payload;
  },
  SET_MAPS_SELECT(state, payload) {
    state.maps.select = payload;
  },
  SET_READY(state, mode){
    state.ready = mode;
  },
  SET_TYPE(state, type){
    state.type = type;
  },
  SET_NOMINATED(state, payload){
    state.nominated = payload;
  },
  SET_NOMINATIONS(state, payload){
    state.nominations = payload;
  },
  SET_VOTING(state, payload){
    state.voting = payload;
  },
  SET_VOTES(state, payload){
    state.votes = payload;
  },
  SET_SELECTED(state, maps){
    state.selected = maps;
  },
  SET_PAUSED(state, parameter){
    state.paused = parameter;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};