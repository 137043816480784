import { createWebHistory, createRouter } from "vue-router";
import ComponentCoreRedirect from "@/components/core/Redirect.vue";
const routes = [
  {
    path: "/",
    name: "ViewHome",
    component: () => import(/* webpackChunkName: "CoreHome" */ '@/views/core/Home.vue')
  },
  {
    path: "/staff",
    name: "ViewContentStaff",
    component: () => import(/* webpackChunkName: "ContentGDPR" */ '@/views/content/Staff.vue')
  },
  {
    path: "/gdpr",
    name: "ViewContentGDPR",
    component: () => import(/* webpackChunkName: "ContentGDPR" */ '@/views/content/GDPR.vue')
  },
  {
    path: "/cookies",
    name: "ViewContentCookies",
    component: () => import(/* webpackChunkName: "ContentCookies" */ '@/views/content/Cookies.vue')
  },
  {
    path: "/play",
    name: "ViewGatherList",
    component: () => import(/* webpackChunkName: "GatherList" */ '@/views/gather/List.vue')
  },
  {
    path: "/play/:token",
    name: "ViewGatherInfo",
    component: () => import(/* webpackChunkName: "GatherInfo" */ '@/views/gather/Info.vue')
  },
  {
    path: "/user",
    name: "ViewUserInfo",
    component: () => import(/* webpackChunkName: "UserInfo" */ '@/views/user/Info.vue')
  },
  {
    path: "/user/login",
    name: "ViewUserLogin",
    component: () => import(/* webpackChunkName: "UserLogin" */ '@/views/user/Login.vue')
  },
  {
    path: "/user/create",
    name: "ViewUserCreate",
    component: () => import(/* webpackChunkName: "UserCreate" */ '@/views/user/Create.vue')
  },
  {
    path: "/user/reset",
    name: "ViewUserForgot",
    component: () => import(/* webpackChunkName: "UserForgot" */ '@/views/user/Forgot.vue')
  },
  {
    path: "/user/reset/:token",
    name: "ViewUserReset",
    component: () => import(/* webpackChunkName: "UserReset" */ '@/views/user/Reset.vue')
  },
  {
    path: "/user/discord",
    name: "ViewUserDiscord",
    component: () => import(/* webpackChunkName: "UserDiscord" */ '@/views/user/Discord.vue')
  },
  {
    path: "/user/steam",
    name: "ViewUserSteam",
    component: () => import(/* webpackChunkName: "UserSteam" */ '@/views/user/Steam.vue')
  },
  {
    path: "/error/404",
    name: "ViewError404",
    component: () => import(/* webpackChunkName: "Error" */ '@/views/core/Error.vue')
  },
  {
    path: "/error/500",
    name: "ViewError500",
    component: () => import(/* webpackChunkName: "Error" */ '@/views/core/Error.vue')
  },
  {
    path: "/:catchAll(.*)",
    component: ComponentCoreRedirect,
    props: { path: 'ViewError404' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;