import axios from '@/helpers/axios';
import dataStore from '@/helpers/data';
import helperError from '@/helpers/error';
import router from '@/router';
const state = {};
const getters = {
  discord: state => state
};
const actions = {
  async auth() {
    this.dispatch('app/loading', true);
    const data = dataStore.getter(this.getters);
    let token = null;
    if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ token = localStorage.getItem('user') }
    else if(data.user.token !== ''){ token = data.user.token }
    else { token = null }
    return await axios.get('/discord/authorize', { headers: { userToken: token } }).then(
      response => {
        if(response.data.response.authorizeUrl) {
          window.location.href = response.data.response.authorizeUrl;
        }
      },
      error => {
        helperError(this, error, 'An error occured.');
      }
    );
  },
  async validate({ commit }, parameters) {
    if(parameters.code == undefined) { 
      this.dispatch('discord/auth', true); } 
    else{
      this.dispatch('app/loading', true);
      const data = dataStore.getter(this.getters);
      let token = null;
      if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ token = localStorage.getItem('user') }
      else if(data.user.token !== ''){ token = data.user.token }
      else { token = "" }
      console.log(token);
      return await axios.post('/discord/authorize', { code: parameters.code }, { headers: { userToken: token } }).then(
        response => {
          this.dispatch('app/loading', false);
          if(data.user.token === ''){
            this.dispatch('notification/text', { type: 'success', msg: 'You have been logged on.', persist: false });
            this.dispatch('user/setToken', response.data.response.userToken);
          } else {
            this.dispatch('notification/text', { type: 'success', msg: 'Steam has been connected.', persist: false });
          }
          this.dispatch('user/setDiscord', true);
          commit('SET_DISCORD');
          router.push({ name: 'ViewUserInfo' });
        },
        error => {
          helperError(this, error, 'An error occured.');
        }
      );
    }
  },
};
const mutations = {
  SET_DISCORD() {
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};