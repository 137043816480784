<template>
  <select class="changelanguage" v-model="language" @change="change">
    <option :key="language.languageId" :value="language.code" v-for="language in data.language.list">
      {{ language.name }}
    </option>
  </select>
</template>

<script>
import dataStore from '@/helpers/data';
export default {
  name: 'ComponentCoreFooterChangeLanguage',
  components: {},
  computed: {
    data() {
      return dataStore.getter(this.$store.getters);
    },
  },
  props: {},
  data() {
    return {
      language: '',
      languages: [],
    };
  },
  created() {
    this.language = this.data.language.current;
    if (localStorage.getItem('language') !== undefined && localStorage.getItem('language') !== null && localStorage.getItem('user') !== '') {
      const language = localStorage.getItem('language');
      this.$store.dispatch('language/current', language);
      this.language = language;
    }
  },
  mounted() {
    this.$store.dispatch('language/list');
  },
  updated() {},
  methods: {
    change() {
      this.$store.dispatch('language/current', this.language).then(() => {
        this.$store.dispatch('page/page', this.data.page.page).then(() => {
          this.$store.dispatch('page/content');
        });
      });
    },
  },
};
</script>
