<template>
  <div class="notification">
    <div
      class="text"
      :class="{
        '-show': data.notification.show,
        '-warning': data.notification.type === 'warning',
        '-error': data.notification.type === 'error',
        '-success': data.notification.type === 'success',
      }"
    >
      <p v-html="data.notification.text + '&nbsp;'"></p>
    </div>
  </div>
</template>

<script>
import dataStore from '@/helpers/data';
export default {
  name: 'ComponentCoreNotification',
  components: {},
  computed: {
    data() {
      return dataStore.getter(this.$store.getters);
    },
  },
  props: {},
  data() {
    return {
      timer: null,
    };
  },
  mounted() {},
  updated() {
    if (this.data.notification.text !== '') {
      clearTimeout(this.timer);
      if (!this.data.notification.persist) {
        this.timer = setTimeout(() => {
          this.$store.dispatch('notification/text', { type: '', msg: '', persist: false });
        }, this.data.notification.time);
      }
    }
  },
  methods: {},
};
</script>
