<template>
  <div class="backdrop" :class="{ '-show': data.app.loading }"></div>
  <div class="spinner" :class="{ '-show': data.app.loading }">
    <div class="animation"></div>
  </div>
</template>

<script>
import dataStore from '@/helpers/data';
export default {
  name: 'ComponentCoreSpinner',
  components: {},
  computed: {
    data() {
      return dataStore.getter(this.$store.getters);
    },
  },
  props: {},
  data() {
    return {};
  },
  mounted() {},
  updated() {},
  methods: {},
};
</script>
