const state = {
  code: 0,
  text: ''
};
const getters = {
  error: state => state
};
const actions = {
  code({ commit }, payload) {
    commit('SET_CODE', payload);
  },
  text({ commit }, payload) {
    commit('SET_TEXT', payload);
  },
};
const mutations = {
  SET_CODE(state, payload) {
    state.code = payload;
  },
  SET_TEXT(state, payload) {
    state.text = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};