<script>
import router from '@/router';
export default {
  name: 'ComponentCoreRedirect',
  components: {},
  computed: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    if (this.path === 'ViewCoreError404') {
      this.$store.dispatch('error/code', 404);
      this.$store.dispatch('error/text', 'Page not found');
    } else if (this.path === 'ViewCoreError500') {
      this.$store.dispatch('error/code', 500);
      this.$store.dispatch('error/text', 'Server error');
    }
    router.push({ name: this.path });
  },
  updated() {},
  methods: {},
};
</script>