import $axios from '@/helpers/axios';
import helperError from '@/helpers/error';
const state = {
  list: [],
  current: 'en'
};
const getters = {
  language: state => state
};
const actions = {
  async list({ commit }) {
    this.dispatch('app/loading', true);
    return await $axios.get('/language').then(
      response => {
        this.dispatch('app/loading', false);
        commit('SET_LIST', response.data.response);
      },
      error => {
        helperError(this, error, 'An error occured.');
      }
    );
  },
  current({ commit }, language) {
    commit('SET_CURRENT', language);
  },
};
const mutations = {
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_CURRENT(state, payload) {
    localStorage.setItem('language', payload);
    state.current = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};