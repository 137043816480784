<template>
  <div v-if="breadcrumb.length > 0 && breadcrumb[0].name !== ''" class="breadcrumb">
    <ul>
      <li>
        <router-link to="/">
          <span>CSLobby</span>
        </router-link>
      </li>
      <li :key="index" v-for="(item, index) in breadcrumb">
        <router-link :to="item.link" v-if="item.link !== ''">
          <span>{{ item.name }}</span>
        </router-link>
        <span v-else v-html="item.name"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import dataStore from '@/helpers/data';
export default {
  name: 'ComponentCoreHeaderBreadcrumb',
  components: {},
  computed: {
    data() {
      return dataStore.getter(this.$store.getters);
    },
  },
  props: {},
  data() {
    return {
      currentPage: '',
      breadcrumb: [],
    };
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        let breadcrumb = [];
        to.path
          .substr(1)
          .split('/')
          .forEach((item, index) => {
            let obj = { name: item, link: '/' + item };
            if (index > 0) {
              obj.link = breadcrumb[index - 1].link + '/' + item;
            }
            if (index > 0) {
              if (breadcrumb[index - 1].name !== 'reset' && breadcrumb[index - 1].name !== 'play') {
                breadcrumb.push(obj);
              } else {
                if (breadcrumb[index - 1].name === 'play') {
                  breadcrumb.push(obj);
                  breadcrumb[breadcrumb.length - 1].name = 'Info';
                }
                if (breadcrumb[index - 1].name === 'reset') {
                  breadcrumb.push(obj);
                  breadcrumb[breadcrumb.length - 1].name = 'Token';
                }
              }
            } else {
              breadcrumb.push(obj);
            }
          });
        breadcrumb[breadcrumb.length - 1].link = '';
        this.breadcrumb = breadcrumb;
      }
    },
  },
  mounted() {},
  updated() {},
  methods: {},
};
</script>
