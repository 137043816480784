<template>
  <Header />
  <Notification />
  <RouterView />
  <Spinner />
  <Footer />
</template>

<script>
import Header from '@/components/core/Header.vue';
import Footer from '@/components/core/Footer.vue';
import Notification from '@/components/core/Notification.vue';
import Spinner from '@/components/core/Spinner.vue';
import dataStore from '@/helpers/data';
import router from '@/router';
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Notification,
    Spinner,
  },
  props: {},
  data() {
    return {
      protected: ['userinfo', 'gatherlist', 'gatherinfo'],
      locked: ['gatherlist', 'gatherinfo'],
      logged: ['userlogin', 'usercreate', 'userforgot', 'userreset'],
    };
  },
  computed: {
    data() {
      return dataStore.getter(this.$store.getters);
    },
  },
  created() {
    if (localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== '') {
      this.$store.dispatch('user/check').then(() => {
        this.checkPage();
      });
    }
  },
  mounted() {},
  updated() {},
  methods: {
    async checkPage() {
      return new Promise((resolve) => {
        if (this.protected.includes(this.data.page.page) && this.data.user.token === '') {
          this.$store.dispatch('notification/text', { type: 'warning', msg: 'You need to login to access this page!' });
          router.push({ name: 'ViewUserLogin' });
          resolve();
        } else if (this.locked.includes(this.data.page.page) && this.data.app.locked) {
          this.$store.dispatch('notification/text', { type: 'warning', msg: 'You need to connect steam and discord to your profile!' });
          router.push({ name: 'ViewUserInfo' });
          resolve();
        } else if (this.logged.includes(this.data.page.page) && this.data.user.token !== '') {
          this.$store.dispatch('notification/text', { type: 'warning', msg: 'Already logged in!' });
          router.push({ name: 'ViewUserInfo' });
          resolve();
        } else {
          resolve();
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        if (from.name !== undefined) {
          this.$store.dispatch('page/from', from.name);
        }
        if (to.name !== undefined) {
          if (to.name === 'ViewGatherInfo') {
            this.$store.dispatch('notification/text', { type: '', msg: '', persist: false });
          }
          if (from.name === 'ViewGatherInfo' || to.name !== 'ViewGatherInfo') {
            this.$store.dispatch('gather/reset');
            this.$store.dispatch('map/reset');
          }
          this.$store.dispatch('page/page', to.name.toLowerCase().substr(4)).then(() => {
            if (from.name !== undefined) {
              this.checkPage().then(() => {
                this.$store.dispatch('page/content');
              });
            } else {
              this.$store.dispatch('page/content');
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_main.scss';
</style>