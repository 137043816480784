export default {
  settings: [
    {
      key: 'map',
      value: {
        name: 'Map',
        type: 'map',
        value: 'Random'
      }
    },
    {
      key: 'mp_friendlyfire',
      value: {
        name: 'Friendly Fire',
        type: 'checkbox',
        value: 0
      }
    },
    {
      key: 'mp_overtime_enable',
      value: {
        name: 'Overtime',
        type: 'checkbox',
        value: 1
      }
    },
    {
      key: 'mp_match_can_clinch',
      value: {
        name: 'Clinch map',
        type: 'checkbox',
        value: 1
      }
    }
  ]
};