import axios from '@/helpers/axios';
import dataStore from '@/helpers/data';
import helperError from '@/helpers/error';
const state = {
  list: [],
  page: '',
  from: '',
  name: '',
  excludes: ['usersteam', 'userdiscord'],
  content: {},
  current: {}
};
const getters = {
  page: state => state
};
const actions = {
  async content({ commit }) {
    const data = dataStore.getter(this.getters);
    if(!state.excludes.includes(data.page.page)){
      if(state.content[data.language.current] === undefined){
        commit('SET_LANGUAGE', data.language.current);
      }
      if(state.content[data.language.current][data.page.page] === undefined){
        this.dispatch('app/loading', true);
        return await axios.get('/page/' + data.page.page, { headers: { language: data.language.current } }).then(
          response => {
            const content = {
              page: data.page.page,
              language: data.language.current,
              data: response.data
            }
            this.dispatch('app/loading', false);
            commit('SET_CONTENT', content);
            commit('SET_CURRENT', state.content[data.language.current][data.page.page]);
          },
          error => {
            helperError(this, error, 'An error occured.');
          }
        );
      } else {
        commit('SET_CURRENT', state.content[data.language.current][data.page.page]);
      }
    }
  },
  async list({ commit }) {
    this.dispatch('app/loading', true);
    return await axios.get('/page').then(
      response => {
        this.dispatch('app/loading', false);
        commit('SET_LIST', response.data.response);
      },
      error => {
        helperError(this, error, 'An error occured.');
      }
    );
  },
  page({ commit }, page) {
    commit('SET_PAGE', page);
  },
  from({ commit }, page) {
    commit('SET_FROM', page);
  },
  name({ commit }, page) {
    commit('SET_NAME', page);
  },
};
const mutations = {
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_FROM(state, payload) {
    state.from = payload;
  },
  SET_NAME(state, payload) {
    state.name = payload;
  },
  SET_LANGUAGE(state, language) {
    state.content[language] = {};
  },
  SET_CONTENT(state, payload) {
    if(payload.data.response){
      state.content[payload.language][payload.page] = payload.data.response;
    } else {
      state.content[payload.language][payload.page] = {};
    }
  },
  SET_CURRENT(state, payload) {
    state.current = payload
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};