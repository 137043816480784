import axios from '@/helpers/axios';
import dataStore from '@/helpers/data';
import helperError from '@/helpers/error';
import router from '@/router';
const state = {};
const getters = {
  steam: state => state
};
const actions = {
  async auth() {
    this.dispatch('app/loading', true);
    const data = dataStore.getter(this.getters);
    let token = null;
    if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ token = localStorage.getItem('user') }
    else if(data.user.token !== ''){ token = data.user.token }
    return await axios.get('/steam/authorize', { headers: { userToken: token } }).then(
      response => {
        if(response.data.response.authorizeUrl) {
          window.location.href = response.data.response.authorizeUrl;
        }
      },
      error => {
        helperError(this, error, 'An error occured.');
      }
    );
  },
  async validate({ commit }, parameters) {
    this.dispatch('app/loading', true);
    const data = dataStore.getter(this.getters);
    let token = null;
    if(localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null && localStorage.getItem('user') !== ''){ token = localStorage.getItem('user') }
    else if(data.user.token !== ''){ token = data.user.token }
    return await axios.post('/steam/authorize', { claimed_id: parameters.claimed_id, identify: parameters.identify, sig: parameters.sig }, { headers: { userToken: token } }).then(
      response => {
        this.dispatch('app/loading', false);
        if(data.user.token === ''){
          this.dispatch('notification/text', { type: 'success', msg: 'You have been logged on.', persist: false });
          this.dispatch('user/setToken', response.data.response.userToken);
        } else {
          this.dispatch('notification/text', { type: 'success', msg: 'Steam has been connected.', persist: false });
        }
        this.dispatch('user/setSteam', true);
        commit('SET_STEAM');
        router.push({ name: 'ViewUserInfo' });
      },
      error => {
        helperError(this, error, 'An error occured.');
      }
    );
  },
};
const mutations = {
  SET_STEAM() {
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};